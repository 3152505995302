import { createStore } from 'vuex';
import $e from '@enfis/quasar';

const title = 'Portál elektronických nákupov';
const description = 'Nástroj na zabezpečenie efektívnych elektronických nákupov a obstarávaní';
const imgUrl = window.location.origin + '/assets/cover_elena.jpg';

export default createStore({
    state: {
        loggedUser: undefined,
        metaData: {
            title: null,
            description: null,
            image: null,
        },
        order: null,
        contactInfo: null,
    },
    getters: {},
    mutations: {
        setMetaData(state, metaData) {
            if (Object.keys(metaData).length === 0) {
                metaData = {
                    title: title,
                    description: description,
                    image: imgUrl,
                };
            } else {
                metaData.title = $e.is.empty(metaData.title) ? title : metaData.title;
                metaData.description = $e.is.empty(metaData.description) ? description : metaData.description;
                metaData.image = $e.is.empty(metaData.image) ? imgUrl : metaData.image;
            }
            state.metaData = metaData;
        },
        setLoggedUser(state, loggedUser) {
            state.loggedUser = loggedUser;
        },
        setOrder(state, order) {
            state.order = order;
        },
        setContactInfo(state, contactInfo) {
            state.contactInfo = contactInfo;
        },
    },
});
