<template>
    <q-layout view="lHh lpr lFf">
        <app-navigation></app-navigation>
        <q-page-container>
            <router-view v-slot="{ Component, route }">
                <transition
                    name="route"
                    mode="out-in"
                >
                    <q-page
                        :key="route.name"
                        :style-fn="$e.utils.pageStyleFn"
                    >
                        <component :is="Component" />
                    </q-page>
                </transition>
            </router-view>
        </q-page-container>
    </q-layout>
</template>

<script>
import AppNavigation from '@/components/navigation.vue';
import { createMetaMixin } from 'quasar';
import { mapState } from 'vuex';

export default {
    components: { AppNavigation },
    mixins: [
        createMetaMixin(function () {
            const isPrefixInTitle = !['home', 'cotoje', 'clanok'].includes(this.$router.currentRoute.value.name);
            const isWebsiteType = ['home', 'clanky'].includes(this.$router.currentRoute.value.name);
            const url = window.location.origin + this.$router.currentRoute.value.fullPath;
            const title = `${isPrefixInTitle ? 'ElenaPortal - ' : ''}${this.metaData.title}`;

            let imgAlt = () => {
                switch (this.$router.currentRoute.value.name) {
                    case 'home':
                        return 'Titulný obrázok portálu ElenaPortal';
                    case 'cotoje':
                        return 'Obrázok podstránky O nás';
                    case 'clanky':
                        return 'Obrázok podstránky Zoznam článkov';
                    case 'cennik':
                        return 'Obrázok podstránky Cenník';
                    case 'otazky':
                        return 'Obrázok podstránky Otázky';
                    case 'onas':
                        return 'Obrázok podstránky O nás';
                    case 'kontakt':
                        return 'Obrázok podstránky Kontakt';
                    default:
                        return 'ElenaPortal';
                }
            };

            let metaDataObj = {
                title: title,
                meta: {
                    title: { name: 'title', content: title },
                    description: {
                        name: 'description',
                        content: this.metaData.description,
                    },
                    ogType: {
                        property: 'og:type',
                        content: isWebsiteType ? 'website' : 'article',
                    },
                    ogUrl: {
                        property: 'og:url',
                        content: url,
                    },
                    ogTitle: {
                        property: 'og:title',
                        content: title,
                    },
                    ogDescription: {
                        property: 'og:description',
                        content: this.metaData.description,
                    },
                    ogImage: {
                        property: 'og:image',
                        content: this.metaData.image,
                    },
                    ogImageSecureUrl: {
                        property: 'og:image:secure_url',
                        content: this.metaData.image,
                    },
                    ogImageAlt: {
                        property: 'og:image:alt',
                        content: imgAlt(),
                    },
                    //      Twitter     //
                    twitterCard: {
                        property: 'twitter:card',
                        content: 'summary_large_image',
                    },
                    twitterUrl: {
                        property: 'twitter:url',
                        content: url,
                    },
                    twitterTitle: {
                        property: 'twitter:title',
                        content: title,
                    },
                    twitterDescription: {
                        property: 'twitter:description',
                        content: this.metaData.description,
                    },
                    twitterImage: {
                        property: 'twitter:image',
                        content: this.metaData.image,
                    },
                },
            };
            return metaDataObj;
        }),
    ],
    mounted() {
        const orgAccept = this.$route.query.org_acc;
        if (!this.$e.is.empty(orgAccept)) {
            this.$e.dialog.info('Pre dokončenie akceptácie pozvánky sa prosím prihláste. Ak konto ešte nemáte, zaregistrujte sa prosím.', null, null, {
                persistent: true,
            });
        }
    },
    data() {
        return {};
    },
    computed: {
        ...mapState(['metaData']),
    },
    methods: {},
};
</script>

<style lang="scss">
// route transition
.route-enter-from {
    opacity: 0;
    transform: scale(0.99);
}
.route-enter-to {
    opacity: 1;
    transition: all 700ms ease;
    transform: scale(1);
}

.q-header {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}
</style>
