import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import MainLayout from '@/layouts/MainLayout.vue';

const routes = [
    {
        path: '/',
        component: MainLayout,
        children: [
            {
                path: '',
                name: 'home',
                component: () => import(/* webpackChunkName: "" */ '@/views/home/homeView.vue'),
            },
            {
                path: '/cotoje',
                name: 'cotoje',
                component: () => import(/* webpackChunkName: "cotoje" */ '@/views/coToJeView.vue'),
            },
            {
                path: '/vyhody',
                name: 'vyhody',
                component: () => import(/* webpackChunkName: "vyhody" */ '@/views/vyhodyView.vue'),
            },
            {
                path: '/cennik',
                name: 'cennik',
                component: () => import(/* webpackChunkName: "cennik" */ '@/views/cennikView.vue'),
            },
            {
                path: '/otazky',
                name: 'otazky',
                component: () => import(/* webpackChunkName: "otazky" */ '@/views/otazkyView.vue'),
            },
            {
                path: '/onas',
                name: 'onas',
                component: () => import(/* webpackChunkName: "onas" */ '@/views/oNasView.vue'),
            },
            {
                path: '/clanky',
                name: 'clanky',
                component: () => import(/* webpackChunkName: "clanky" */ '@/views/clanky/zoznamClankovView.vue'),
            },
            {
                path: '/clanok/:id',
                name: 'clanok',
                component: () => import(/* webpackChunkName: "clanok" */ '@/views/clanky/clanokView.vue'),
            },
            {
                path: '/prechod',
                name: 'prechod',
                component: () => import(/* webpackChunkName: "prechod" */ '@/views/clanky/prechodView.vue'),
            },
            {
                path: '/kontakt',
                name: 'kontakt',
                component: () => import(/* webpackChunkName: "kontakt" */ '@/views/kontaktView.vue'),
            },

            {
                path: '/vseobecne_obchodne_podmienky',
                name: 'vseobecne_obchodne_podmienky',
                component: () => import(/* webpackChunkName: "vseobecne_obchodne_podmienky" */ '@/views/vop.vue'),
            },
            {
                path: '/pravidla_spracovania_osobnych_udajov',
                name: 'pravidla_spracovania_osobnych_udajov',
                component: () => import(/* webpackChunkName: "pravidla_spracovania_osobnych_udajov" */ '@/views/psou.vue'),
            },

            {
                path: '/zakazka',
                name: 'zakazka/root',
                redirect: { name: 'zakazka' },
                component: () => import('@/views/zakazka/Index.vue'),
                props: true,
                children: [
                    {
                        path: ':id',
                        name: 'zakazka',
                        component: () => import(/* webpackChunkName: "zakazka" */ '@/views/zakazka/ZakazkaMainView.vue'),
                        redirect: { name: 'zakazka/detail' },
                        props: true,
                        children: [
                            {
                                name: 'zakazka/detail',
                                path: '',
                                component: () => import(/* webpackChunkName: "zakazka/detail" */ '@/views/zakazka/DetailZakazkyView.vue'),
                                props: true,
                            },
                            {
                                name: 'zakazka/castizakazky',
                                path: 'castizakazky',
                                component: () => import(/* webpackChunkName: "zakazka/castizakazky" */ '@/views/zakazka/castiZakazky/ListView.vue'),
                                props: true,
                            },
                            {
                                name: 'zakazka/prilohyadokumenty',
                                path: 'prilohyadokumenty',
                                component: () => import(/* webpackChunkName: "zakazka/prilohyadokumenty" */ '@/views/zakazka/prilohyADokumenty/ListView.vue'),
                                props: true,
                            },
                        ],
                    },
                    {
                        name: 'zakazka/castizakazky/cast',
                        path: ':id/castizakazky/:partid',
                        component: () => import(/* webpackChunkName: "zakazka/castizakazky/cast" */ '@/views/zakazka/castiZakazky/DetailView.vue'),
                        props: true,
                    },
                    {
                        name: 'zakazka/prilohyadokumenty/priloha',
                        path: ':id/prilohyadokumenty/:docid',
                        component: () => import(/* webpackChunkName: "zakazka/prilohyadokumenty/priloha" */ '@/views/zakazka/prilohyADokumenty/DetailView.vue'),
                        props: true,
                    },
                ],
            },
        ],
    },
    { name: 'catch', path: '/:catchAll(.*)*', redirect: '/' },
];

const router = createRouter({
    history:
        process.env.VUE_APP_ROUTER_MODE && process.env.VUE_APP_ROUTER_MODE == 'hash'
            ? createWebHashHistory(process.env.BASE_URL)
            : createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
