<template>
    <q-header class="bg-white">
        <q-toolbar class="navigacia-navbar-interactive q-pa-none">
            <router-link
                to="/"
                class="flex noHover"
            >
                <img alt="elena_logo" src=@/assets/elenaLogo.svg loading="lazy" class="navigacia-logo" />
            </router-link>
            <q-space />
            <div class="row gt-sm">
                <q-btn
                    flat
                    label="Úvod"
                    color="dark"
                    class="nav-link text-bold"
                    to="/"
                />

                <q-btn
                    flat
                    label="Čo to je?"
                    color="dark"
                    class="nav-link text-bold"
                    to="/cotoje"
                />

                <q-btn
                    flat
                    label="Články"
                    color="dark"
                    class="nav-link text-bold"
                    to="/clanky"
                />

                <q-btn
                    flat
                    label="Cenník"
                    color="dark"
                    class="nav-link text-bold"
                    to="/cennik"
                />

                <q-btn
                    flat
                    label="Otázky"
                    color="dark"
                    class="nav-link text-bold"
                    to="/otazky"
                />

                <q-btn
                    flat
                    label="O nás"
                    color="dark"
                    class="nav-link text-bold"
                    to="/onas"
                />

                <q-btn
                    flat
                    label="Kontakt"
                    color="dark"
                    class="nav-link text-bold"
                    to="/kontakt"
                />
            </div>
            <q-btn
                flat
                @click="openedMenu = !openedMenu"
                dense
                icon="menu"
                size="lg"
                class="hamburger lt-md"
                color="dark"
            />
        </q-toolbar>
    </q-header>
    <div class="lt-md">
        <q-drawer
            v-model="openedMenu"
            persistent
            elevated
            overlay
            side="right"
            class="right-menu bg-white text-dark lt-md"
        >
            <div class="column full-height no-wrap">
                <div
                    class="flex col-shrink justify-end q-px-md q-py-sm"
                    style="height: 56px"
                >
                    <q-btn
                        flat
                        @click="openedMenu = !openedMenu"
                        dense
                        icon="close"
                        size="lg"
                        class="hamburger lt-md"
                        color="dark"
                    />
                </div>
                <div class="col-grow">
                    <q-scroll-area class="full-height">
                        <div class="column">
                            <q-btn
                                flat
                                label="Úvod"
                                color="dark"
                                class="nav-link text-bold"
                                size="lg"
                                to="/"
                                @click="toggleMenu"
                            />

                            <q-btn
                                flat
                                label="Čo to je?"
                                color="dark"
                                class="nav-link text-bold"
                                size="lg"
                                to="/cotoje"
                                @click="toggleMenu"
                            />
                            <q-btn
                                flat
                                label="Články"
                                color="dark"
                                class="nav-link text-bold"
                                size="lg"
                                to="/clanky"
                                @click="toggleMenu"
                            />

                            <q-btn
                                flat
                                label="Cenník"
                                color="dark"
                                class="nav-link text-bold"
                                size="lg"
                                to="/cennik"
                                @click="toggleMenu"
                            />

                            <q-btn
                                flat
                                label="Otázky"
                                color="dark"
                                class="nav-link text-bold"
                                size="lg"
                                to="/otazky"
                                @click="toggleMenu"
                            />

                            <q-btn
                                flat
                                label="O nás"
                                color="dark"
                                class="nav-link text-bold"
                                size="lg"
                                to="/onas"
                                @click="toggleMenu"
                            />

                            <q-btn
                                flat
                                label="Kontakt"
                                color="dark"
                                class="nav-link text-bold"
                                size="lg"
                                to="/kontakt"
                                @click="toggleMenu"
                            />
                        </div>
                    </q-scroll-area>
                </div>
            </div>
        </q-drawer>
    </div>
</template>

<script>
import { scroll } from 'quasar';
const { getScrollTarget, setVerticalScrollPosition } = scroll;

export default {
    name: 'Navigation',
    data() {
        return {
            openedMenu: false,
        };
    },
    methods: {
        toggleMenu: function () {
            this.openedMenu = !this.openedMenu;
        },
    },
};
</script>

<style lang="scss" scoped>
.navigacia-container {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    z-index: 1;
    position: relative;
    flex-wrap: nowrap;
    background: white;
    align-items: flex-start;
    flex-direction: column;
}
.navigacia-navbar-interactive {
    width: 100vw;
    display: flex;
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    margin-left: auto;
    padding: 16px 48px;
    margin-right: auto;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ffffff;
}

.nav-link {
    text-decoration: unset;
    text-transform: unset;
    font-weight: 600;
    transition: 0.3s;
    font-size: 19px !important;
    min-height: 56px !important;
    padding: 0 18px !important;
    @include border-radius(8px);

    &:hover {
        color: #002876;
    }
}
.hamburger {
    min-height: auto !important;
    padding: 0.285em !important;
}

.right-menu {
    .nav-link {
        @include border-radius(0 !important);
        font-size: 19px !important;
    }
}

.navigacia-logo {
    height: 3rem;
    transition: height 0.3s;
    text-decoration: none;
    @media (max-width: $breakpoint-sm-max) {
        height: 35px;
    }
}
.navigacia-icon {
    fill: #052868;
    width: 32px;
    height: 32px;
}
.navigacia-text {
    color: #052868;
    font-weight: 600;
    padding-left: 5px;
}
.navigacia-mobile-menu {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    display: flex;
    z-index: 100;
    position: fixed;
    transform: translateY(-100%);
    transition: 0.5s;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
}
.navigacia-container1 {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-threeunits);
    justify-content: space-between;
    padding: 10px 15px;
}

@media (max-width: $breakpoint-sm-max) {
    .navigacia-navbar-interactive {
        padding: 8px 16px;
    }
    .navigacia-nav {
        justify-content: space-between;
    }
}
@media (max-width: 320px) {
    .navigacia-mobile-menu {
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
    }
}
</style>
