import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import Quasar from 'quasar/src/vue-plugin.js';import QSpinnerPuff from 'quasar/src/components/spinner/QSpinnerPuff.js';;
import quasarUserOptions from './quasar-user-options';
import { EnfisQuasar } from '@enfis/quasar';
import 'animate.css';

createApp(App)
    .use(Quasar, quasarUserOptions)
    .use(i18n)
    .use(store)
    .use(router)
    .use(EnfisQuasar, {
        config: {
            io: { baseURL: window.config.enfisBaseUrl },
            loading: {
                spinner: QSpinnerPuff,
                spinnerColor: 'blue-6',
                spinnerSize: 100,
            },
        },
    })
    .mount('#app');
