<template>
    <router-view />
</template>

<script>
export default {
    mounted() {
        // Zadefinovanie globálnej metódy aby sa dala volať aj z footera
        this.$e.showCookieNotify = () => {
            this.$q.notify({
                classes: 'acceptCookiesNotify',
                message:
                    '<p><b>Súhlas s používaním cookies</b></p>' +
                    '<p>Používame cookies na zlepšenie vašej užívateľskej skúsenosti a poskytovanie relevantných reklám a služieb. Tieto cookies zahŕňajú aj cookies vytvorené pomocou analytických nástrojov, ktoré nám pomáhú meriať efektivitu našich reklám a optimalizovať ich pre našich návštevníkov.</p>' +
                    '<p>Kliknutím na tlačidlo "Súhlasím" vyjadrujete súhlas s ich používaním. Ak si želáte zobraziť podrobnosti o tom, ako spracúvavame osobné údaje, prejdite na našu stránku s pravidlami ich spracovania stránke <a href="kontakt" target="_blank" style="color: #16a71b;"><b>Kontakt</b></a>.</p>' +
                    '<p>Ďakujeme</p>',
                color: 'primary',
                position: 'bottom-right',
                multiLine: true,
                timeout: 0,
                html: true,
                actions: [
                    {
                        label: 'Súhlasím',
                        color: 'white',
                        class: 'acceptCookiesButton',
                        handler: () => {
                            this.$q.localStorage.set('elena-web_accept-cookies', true);
                            location.reload();
                        },
                    },
                    {
                        label: 'Nesúhlasím',
                        color: 'white',
                        class: 'declineCookiesButton',
                        handler: () => {
                            this.$q.localStorage.set('elena-web_accept-cookies', false);
                            location.reload();
                        },
                    },
                ],
            });
        };

        this.$e.io.callMethod(
            'getSetting',
            {
                'setting-setting_id': 28, //Kontaktny email
            },
            {
                success: (data) => {
                    let contactInfo = {
                        kontaktnyMail: data.value,
                    };
                    this.$store.commit('setContactInfo', contactInfo);

                    let showCookiesNotify = this.$q.localStorage.has('elena-web_accept-cookies');
                    if (!showCookiesNotify) {
                        setTimeout(() => {
                            this.$e.showCookieNotify();
                        }, 1500);
                    }
                },
                error: (error) => {
                    return false;
                },
            }
        );
    },
    data() {
        return {};
    },
};
</script>
